import { Manipulation } from "swiper";
import Vivus from "vivus";

const [d,mq] = [document, window.matchMedia('(orientation: landscape), (min-width: 750px)')];

export default class SPLASH {
  constructor (){
    this.container = `
      <section class="splash">
        <div class="splash__container">
          <div class="splash__logo">
            <svg id="mask_anim" style="opacity: 0;" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32.14 304.71">
              <mask id="clipmask"><path class="st1" d="M9.05,1.5s-1.12,46.32,.65,55.85,8.95,13.07,10.24,15.29,.4,3.55-6.03,6.44-18.03,20.86-6.87,19.51,13.08-2.11,14.52,2.11c0,0-1.55,5.1-17.4,10.31-2.9-.84,2.44-30.64,21.28-20.17,11.37,6.31,.89,5.76-1.55,6.43s-16.65,4.33-11.97,16.85,3.6,16.2,.58,18.63-12.11,7.2-10.89,.88,10.31-4.54,16.74-.11,6.43,6.65,6.43,6.65l2.33,1.22s4.66-.11-2.44,5.87-13.3,24.39-14.52,30.04-4.99,20.73-2.22,33.25c2.77,12.53,18.4,13.52,18.4,13.52,0,0,3.33,8.42-10.42,12.3s-12.64,11.42-12.64,11.42l17.07,.33-9.2,7.32s.55,2.33,3.99,.33,4.32-4.43,4.32-4.43l-.22-1.33s-4.21,1.22-4.88,4.77,1.44,13.75,2.11,15.85-1.11,12.64-6.1,15.19-4.99,2.11-4.99,.55,2.66-3.88,8.2,.11c5.54,3.99,10.97,8.09,12.75,13.3l-.11,3.44"
                /></mask>
              <image xlink:href="/assets/img/home/splash_logo.svg#logo" width="100%" height="100%" mask="url(#clipmask)"
              ></image>
            </svg>
          </div>
          <div class="splash__bg" data-plugin="splashBg"></div>
        </div>
      </section>`;
      this.init();
      this.promise = new Promise(r => this.resolve = r);
      d.addEventListener('DOMContentLoaded', () => {
        this.domReady()
          .then( bg => {
            this.splashPlay(bg);
            setTimeout(() => this.anim(), 3800);
          })
          .then( bg => this.splashRemove(bg) )
          .then( () => this.resolve() )
      });
        
    return this.promise;
  }
  
  init() {
    d.documentElement.classList.add('is-active-splash');
  }

  anim() {
    const svg = d.querySelector('#mask_anim');
    svg.style.opacity = 1;

    // 一筆書きアニメ
    new Vivus(svg, {
      type: 'oneByOne',
      duration: 150,
      forceRender: false,
      pathTimingFunction: Vivus.LINEAR,
      animTimingFunction: Vivus.LINEAR
    });
  }

  domReady() {
    d.body.insertAdjacentHTML('beforeend', this.container);
    const bg = d.querySelector('.splash__bg');
    return Promise.resolve(bg);
  }

  splashPlay (bg) {
    bg.style.opacity = 1;
    setTimeout(() => bg.classList.add('is-move'), 600); // transition: 800ms
  }

  splashStop () {
    let resolve;
    const removed = new Promise(res => resolve = res);
    d.documentElement.classList.add('is-fadeout-splash');

    setTimeout(() => {
      d.documentElement.classList.remove('is-fadeout-splash');
      d.documentElement.classList.remove('is-active-splash');
      resolve(); // transition: 600ms + 600ms
    }, 600+600);
    return removed;
  }

  splashRemove () {
    let resolve;
    const removed = new Promise(res => resolve = res);
    setTimeout(() => {
      d.documentElement.classList.add('is-fadeout-splash');
    }, 6400); // transition: 800ms

    setTimeout(() => {
      d.documentElement.classList.remove('is-fadeout-splash');
      d.documentElement.classList.remove('is-active-splash');
      resolve(); // transition: 600ms + 600ms
    }, 6400+600+600);
    return removed;
  }
}

