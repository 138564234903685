import Utility from "./class/utility";
import CUSTOMIZE from "./extended_class/customize";
import CONCEPT from "./module/concept";
import HOME from "./module/home";
import LETTER from "./module/letter";
import WORKS from "./module/works";
import DETAIL from "./module/details";

// ユーティリティクラスのインスタンス化
const UTIL = new Utility();

// テンプレートインスタンス化（promise登録）
const TPL = new CUSTOMIZE();

// URLによる関数の実行
UTIL.loader( (request, path) => {
  switch( path ){
    case '/': HOME(); break
    case '/concept/': CONCEPT(); break
    case '/letter/': LETTER(); break
    case '/works/': WORKS(); break
    case '/details/': DETAIL(); break
  }
  // switch( request ){}
});

// テンプレートインスタンスの初期化（イベント発火）
TPL.init();