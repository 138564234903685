import TAGSORT from "../class/tagsort";

const [ w, d ] = [window, document ];

const detailNavApplyData = obj => {
  if( obj.dataset.id ){
    let [html, content, post_id, tagName ] = ['', 'works', obj.dataset.id, obj.tagName.toLowerCase() ];

    TAGSORT.getArticles({ content, post_id }).then( res => {
      switch (tagName) {
        case 'subject':
          html = `<span class="subject">${res[0].title.str_truncate(25)}</span>`;
          break
        case 'thumb':
          html = `<img class="thumb" src="${res[0].thumb}" alt="${res[0].title}" />`;
          break
      }
      obj.insertAdjacentHTML('afterend', html );
      obj.remove();
    });
  }
}

const domReady = TPL => {

  //記事内の画像を特定のブロックに移動
  const imgAppend = () => {
    const imgBlock = d.querySelector('.works-detail__img');
    const imgs = d.querySelectorAll('.works-detail__body img');
    imgs.forEach( img => imgBlock.appendChild(img));
  }
  imgAppend();

  //ページネーション
  if( d.body.classList.contains('is--detail') ) {
    let targetObj = d.querySelectorAll('subject[data-id],thumb[data-id]');
    targetObj.forEach( detailNavApplyData );
  }

};


const jqInit = () => false;

export default function WORKS () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
}
