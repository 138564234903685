import LazyDisp from "../class/lazydisp";

const [ w, d ] = [window, document ];

const domReady = () => false;

const jqInit = () => false;

const onLoadHtml = () => {
  // LazyDisp開始
  new LazyDisp('[data-lazydisp]');
};

export default function CONCEPT () {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  $(() => jqInit());
  $(() => Promise.all([w.load['loadHtml']]).then( onLoadHtml ) );
}
