import TAGSORT from "../class/tagsort";

const [w, d ] = [window, document];

// テンプレート上書き
const itemTemplate = `
    <div class="details-list" :class="[ 'is-paged-' + paged ]">
      <div class="details-list__inner">
        <transition-group tag="div" class="details-list__list" name="fade" mode="out-in">
          <a :href="article.href" class="details-list-item" v-for="( article, ind ) in pagedArticles" :key="article.id" :class="[ 'is-item-' + ind ]">
            <div class="details-list-item__thumb">
              <span class="cate" v-if="article.category.name">{{ article.category.name }}</span>
              <img :src="article.thumb" :alt="article.title" class="details-list-item__img" width="288" height="192">
            </div>
            <!-- /.details-list-item__thumb -->
            <div class="details-list-item__body">
              <span class="title" :class="{'is-new': article.is_new}">{{ article.title | truncate(24) }}</span>
              <div class="tags" v-if="article.keywords.length">
                <span class="tag" v-for="keyword in article.keywords.slice(0,4)">#{{keyword}}</span>
              </div>
            </div>
            <!-- /.details-list-item__body -->
          </a>
        </transition-group>
        <!-- /.details-list__list -->
      </div>
      <!-- /.details-list__inner -->
    </div>
`;

const pagination_template = `
  <div class="rwd002-pagination">
    <!-- /.rwd002-pagination__totalpages -->
    <div class="rwd002-pagination__pageNav">
      <ul class="rwd002-pagination__pages">
        <li v-for="page in pagelist">
          <i v-if="page == '...'">{{page}}</i>
          <a v-if="page != paged && page != '...'" :href="suffix + page" @click.prevent="changePage(page)">{{page}}</a>
          <span v-if="page == paged">{{page}}</span>
        </li>
      </ul>
      <!-- /.rwd002-pagination__pages -->
      <a :href="suffix + prevPage" class="is-prev" :class="[is_first ? 'disable': '']" @click.prevent="prev"><span>{{ prevText }}</span></a>
      <a :href="suffix + nextPage" class="is-next" :class="[is_last ? 'disable': '']" @click.prevent="next"><span>{{ nextText }}</span></a>
    </div>
    <!-- /.rwd002-pagination__pageNav -->
  </div>
`;

const detailNavApplyData = obj => {
  if( obj.dataset.id ){
    let [html, content, post_id, tagName ] = ['', 'details', obj.dataset.id, obj.tagName.toLowerCase() ];
    TAGSORT.getArticles({ content, post_id }).then( res => {
      switch (tagName) {
        case 'subject':
          html = `<span class="subject">${res[0].title.str_truncate(25)}</span>`;
          break
        case 'thumb':
          html = `<img class="thumb" src="${res[0].thumb}" alt="${res[0].title}" />`;
          break
      }
      obj.insertAdjacentHTML('afterend', html );
      obj.remove();
    });
  }
}

const domReady = TPL => {

  let Tagsort = new TAGSORT({
    list: {
      perpage: 12, // 一度に表示する記事数
      itemTemplate, // テンプレートの上書き（falseでデフォルト表示）
    },
    taglist: {
      container: !d.body.classList.contains('is--list')? '.rwd002-pagination': false,
      disp_list: 'head',
      disp_detail: 'foot'
    },
    pagination: {
      template: pagination_template
    },
    related: {
      container: '.rwd002-pagination',
      amount: 4,
      disp_detail: true,
      itemTemplate: `
      <div class="details-related is-list-12" v-if="relatedArticles.length">
        <div class="details-related__inner">
          <h3 class="details-related__title">関連記事</h3>
          <!-- /.details-related___title -->
          <div class="details-related__list">
            <a :href="article.href" class="details-related-item" v-for="( article, ind ) in relatedArticles" :key="article.id" :class="[ 'is-item-' + ind ]">
              <div class="details-related-item__thumb"><img :src="article.thumb" :alt="article.title" width="100%" loading="lazy" class="details-related-item__img"><span class="cate" :class="'is-cat'+article.category.id" :data-href="article.category.href" v-if="article.category">{{ article.category.name }}</span></div>
              <div class="details-related-item__body">
                <h5 class="title">{{ article.title|truncate(24) }}</h5>
                <div class="tags" v-if="article.keywords.length">
                  <span class="tag" v-for="keyword in article.keywords.slice(0,4)">#{{keyword}}</span>
                </div>
              </div>
              <!-- /."details-related-item__body -->
            </a>
            <!-- /.details-related-item -->
          </div>
          <!-- /.details-related__list -->
        </div>
        <!-- /.details-related___inner -->
      </div>
      <!-- /.details-related_ -->
      `,
    }
  });

  Tagsort.render();

  if( Tagsort.is_detail() ){
    let targetObj = d.querySelectorAll('subject[data-id],thumb[data-id]');
    targetObj.forEach( detailNavApplyData );
  }
};

export default function DETAIL() {
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
}