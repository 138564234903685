import Swiper from "swiper/bundle";
import GET_ARTICLES from "../class/getArticles";
import SPLASH from '../class/splash';

const [w, d ] = [window, document];

const tagList = tags => {
  const $tags = $('<div class="tags"/>');
  tags = tags.slice(0,4);
  tags.forEach( tag => {
    const $tag = $(`<span class="tag"/>#${tag}</span>`);
    $tag.appendTo( $tags );
  });
  return $tags[0].outerHTML;
};

class Blocks {
  constructor () {
    this.init();
  }
  init(){
    this.works();
    this.letter();
    this.detail();
    this.info();
  }

  mainvisual() {
    let obj = d.querySelector('.mainvisual');
    obj.classList.add('is-ready');
  }

  works () {
    const slider = new Swiper('.block-works__slider', {
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: "auto",
      breakpoints: {
        900: {
          centeredSlides: true,
          loop: true,
          loopedSlides: 9,
        }
      },
      navigation: {
        nextEl: '.block-works .swiper-button-next',
        prevEl: '.block-works .swiper-button-prev',
      },
    });

    slider.removeAllSlides();
    const getArticles = new GET_ARTICLES('.block-works__list[data-plugin="getArticles"]');
    const getContentData = Promise.all([
      getArticles.fetchArticles({
        content: getArticles.containers[0].dataset.content,
        post_per_page: getArticles.containers[0].dataset.amount,
      }),
      getArticles.fetchCategories( getArticles.containers[0].dataset.content ),
    ]).then( res => Promise.all(res.map(r=>r.json())) );
    const addSlider = item => {

      slider.appendSlide([`
        <a href="${item.href}" class="block-works-item swiper-slide">
          <div class="block-works-item__thumb">
            <img src="${item.thumb}" alt="${item.title}" class="block-works-item__img" width="800" height="630" loading="lazy">
          </div>
          <!-- /.block-works-item__thumb -->
          <div class="block-works-item__detail">
            <span class="title">${item.title}</span>
          </div>
          <!-- /.block-works-item__detail -->
        </a>
        <!-- /.block-works__item swiper-slide -->
      `]);
    }

    getContentData.then(([articles, cats]) => {
      getArticles.containers[0].innerHTML = '';
      // 記事整形
      articles = articles.map(item => getArticles.optimizeArticle(item,cats,getArticles.containers[0].dataset));
      articles.forEach( addSlider );
      slider.updateSlides();
      slider.update();
      slider.slideToLoop(0);
    });
      
  }

  letter() {
    const getArticle = new GET_ARTICLES('.block-letter__list[data-plugin="getArticles"]');
    getArticle.makeItem = item => `
    <a href="${item.href}" class="block-letter-item">
      <div class="block-letter-item__thumb">
        ${item.category ? `<span class="category">${item.category.name}</span>` : ''}
        <img src="${item.thumb}" alt="${item.title}" class="block-letter-item__img" width="288" height="192" loading="lazy">
      </div>
      <!-- /.block-letter-item__thumb -->
      <div class="block-letter-item__body">
        <span class="title${item.is_new? ' is-new':''}">${item.title}</span>
        ${item.keywords ? tagList(item.keywords) : ''}
        <span class="date">${item.date}</span>
      </div>
      <!-- /.block-letter-item__body -->
    </a>`;
    getArticle.render().then( ([[articles]]) => {
      if( articles.length == 0 ) getArticle.containers.forEach( elm => {
        elm.closest('.block-letter').remove();
      })
    });
  }

  detail() {
    const getArticle = new GET_ARTICLES('.block-detail__list[data-plugin="getArticles"]');
    getArticle.makeItem = item => `
    <a href="${item.href}" class="block-detail-item">
      <div class="block-detail-item__thumb">
        <img src="${item.thumb}" alt="${item.title}" class="block-detail-item__img" width="280" height="280" loading="lazy">
      </div>
      <!-- /.block-detail-item__thumb -->
      <div class="block-detail-item__body">
        <span class="title">${item.title.str_truncate(24)}</span>
      </div>
      <!-- /.block-detail-item__body -->
    </a>`;
    getArticle.render().then( ([[articles]]) => {
      if( articles.length == 0 ) getArticle.containers.forEach( elm => {
        elm.closest('.block-detail').remove();
      });
    });
  }

  info() {
    const getArticle = new GET_ARTICLES('.block-info__list[data-plugin="getArticles"]');
    getArticle.makeItem = item => `
    <a href="${item.href}" class="block-info-item">
      <div class="block-info-item__thumb">
      <img src="${item.thumb}" alt="${item.title}" class="block-info-item__img" width="180" height="180" loading="lazy">
      </div>
      <!-- /.block-info-item__thumb -->
      <div class="block-info-item__body">
        <span class="date-cate${item.is_new? ' is-new':''}">
          <span class="date">${item.date}</span>
          ${item.category ? `<span class="category">[${item.category.name}]</span>` : ''}
        </span>
        <!-- /.date-cate -->
        <span class="title">${item.title.str_truncate(24)}</span>
      </div>
      <!-- /.block-info-item__body -->
    </a>`;
    getArticle.render().then( ([[articles]]) => {
      if( articles.length == 0 ) getArticle.containers.forEach( elm => {
        elm.closest('.block-info').remove();
      })
    });
  }
}

const domReady = TPL => {
  let blocks = new Blocks;
  w.addEventListener('load', () => blocks.mainvisual() );
};

const jqInit = () => false;

export default function HOME () {
  const splash = new SPLASH;
  // テンプレートが用意したDOMContentLoaded（テンプレートインスタンスが引数に入る）
  d.addEventListener('rwd002.beforeDomready', e => domReady(e.detail) );
  
  $(() => jqInit());
}